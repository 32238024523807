import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './Gallery.css'; // Ensure you create and import this CSS file

const styles = {
  GalleryContainer: {
    padding: '16px',
    textAlign: 'center',
    position: 'relative',
    maxWidth: '800px',
    margin: '0 auto',
  },
  Title: {
    color: '#333333',
    fontSize: '20px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    lineHeight: '24px',
    marginBottom: '16px',
  },
  CardContainer: {
    backgroundColor: '#fdead7',
    borderRadius: '11px',
    boxShadow: '2px 0px 10px rgba(3,3,3,0.1)',
    padding: '16px',
    marginBottom: '16px',
  },
  Image: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
  },
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow`} // Add a custom class name
      style={{ ...style, display: "block", right: '10px' }}
      onClick={onClick}
    />
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow`} // Add a custom class name
      style={{ ...style, display: "block", left: '10px' }}
      onClick={onClick}
    />
  );
};

const Gallery = (props) => {
  const images = [
    require('../images/geese-1.jpg'), // Replace with actual image paths
    require('../images/geese-2.jpg'),
    require('../images/geese-3.jpg'),
    require('../images/geese-4.jpg'),
    require('../images/geese-5.jpg'),
    require('../images/geese-6.jpg'),
    require('../images/geese-7.jpg'),
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <div style={styles.GalleryContainer}>
      <div style={styles.Title}>
        {props.title ?? 'Gallery'}
      </div>
      <div style={styles.CardContainer}>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Gallery ${index + 1}`} style={styles.Image} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Gallery;
