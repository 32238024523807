import React from 'react';
import Header from './components/Header';
import Home from './components/Home';
import Pricing from './components/Pricing';
import AvailableDates from './components/AvailableDates';
import Accommodations from './components/Accomodations';
import Gallery from './components/Gallery';
import BookYourHunt from './components/BookYourHunt';
import FAQ from './components/FAQ';
import ContactUs from './components/ContactUs';

// App.js
const styles = {
  Screen: {
    backgroundColor: '#ffffff',
  },
};

const App = () => {
  return (
    <div style={styles.Screen}>
      <Header />
      <section id="Home">
      <Home />
      </section>
      <section id="Pricing">
        <Pricing />
      </section>
      <section id="Available-Dates">
        <AvailableDates />
      </section>
      <section id="Accommodations">
        <Accommodations />
      </section>
      <section id="Gallery">
        <Gallery />
      </section>
      <section id="FAQ">
        <FAQ />
      </section>
      <section id="BookYourHunt">
        <BookYourHunt />
      </section>
      <section id="ContactUs">
        <ContactUs />
      </section>
      {/* Other components can go here */}
    </div>
  );
};

export default App;
