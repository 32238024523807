import React from 'react';
import geeseHunters from '../images/geese-hunters-1.jpg';
const styles = {
  HomeContainer: {
    padding: '16px',
    textAlign: 'center',
    position: 'relative',
  },
  ImageContainer: {
    width: '100%',
    height: 'auto',
    backgroundImage: `url(${geeseHunters})`,
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: '75%',
    marginTop: '64px',
    marginBottom: '16px',
  },
  Title: {
    color: '#333333',
    fontSize: '32px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    lineHeight: '40px',
    marginBottom: '16px',
  },
  CardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px',
    padding: '16px', // Adjust padding for spacing around the text
    backgroundColor: '#fdead7',
    borderRadius: '11px',
    boxShadow: '2px 0px 10px rgba(3,3,3,0.1)',
    maxWidth: '800px', // Adjust the width of the card
    margin: '0 auto', // Center the card horizontally
  },
  Content: {
    color: '#030303',
    fontSize: '16px',
    fontFamily: 'Roboto',
    lineHeight: '24px',
  },
};

const Home = (props) => {
  return (
    <div style={styles.HomeContainer}>
      <div style={styles.ImageContainer} />
      <div className="HeaderText" style={styles.Title}>
        {props.title ?? 'Guided Snow Goose Hunts in Missouri'}
      </div>
      <div style={styles.CardContainer}>
        <div style={styles.Content}>
          {props.content ?? 'Join us for an unforgettable hunting experience in Northwest Missouri. Our expert guides will ensure you have a successful and memorable hunt.'}
        </div>
      </div>
    </div>
  );
};

export default Home;
