import React from 'react';

const styles = {
  FooterContainer: {
    padding: '16px',
    textAlign: 'left',
    position: 'relative',
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: '#030303',
    color: '#ffffff',
    borderTop: '1px solid #e5e7eb',
    borderRadius: '0 0 11px 11px',  // Rounded corners at the bottom
  },
  Text: {
    fontSize: '14px',
    fontFamily: 'Roboto',
    lineHeight: '16px',
    marginBottom: '8px',
  },
  ContactInfo: {
    marginBottom: '16px',
  },
  SocialLinks: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '8px',
  },
  SocialIcon: {
    width: '24px',
    height: '24px',
    fill: '#ffffff',  // White color for icons
  },
};

const ContactUs = (props) => {
  return (
    <div style={styles.FooterContainer}>
      <div style={styles.Text}>
        {props.title ?? 'Contact Us'}
      </div>
      <div style={styles.ContactInfo}>
        <div style={styles.Text}>
          Email: krinkeoutfitters@gmail.com
        </div>
        <div style={styles.Text}>
          Phone: (651) 248-6777
        </div>
      </div>
      <div style={styles.SocialLinks}>
        <a href="https://www.facebook.com/profile.php?id=61562828775232" target="_blank" rel="noopener noreferrer">
          <svg style={styles.SocialIcon} viewBox="0 0 24 24">
            <path d="M22.675 0h-21.35c-0.733 0-1.325 0.592-1.325 1.325v21.351c0 0.732 0.592 1.324 1.325 1.324h11.49v-9.284h-3.126v-3.622h3.126v-2.671c0-3.1 1.892-4.792 4.655-4.792 1.324 0 2.463 0.099 2.794 0.143v3.24h-1.918c-1.503 0-1.794 0.714-1.794 1.762v2.318h3.586l-0.467 3.622h-3.119v9.284h6.102c0.732 0 1.325-0.592 1.325-1.324v-21.351c0-0.733-0.593-1.325-1.325-1.325z"/>
          </svg>
        </a>
        {/* Add more social icons as needed */}
      </div>
    </div>
  );
};

export default ContactUs;
