import React, { useState } from 'react';

const styles = {
  Header: {
    top: '0px',
    left: '0px',
    width: '100%',
    height: '64px',
    backgroundColor: '#030303',
    borderBottom: '1px solid #e5e7eb',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
    position: 'fixed',  // Fix the header to the top of the screen
    zIndex: 1000,
    margin: '0',  // Remove any default margin
  },
  Text: {
    color: '#ffffff',
    fontSize: '24px',
    fontFamily: 'Roboto',
    lineHeight: '28px',
  },
  LogoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  Icon: {
    color: '#ffffff',
    fill: '#ffffff',
    fontSize: '24px',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  MenuItemHover: {
    backgroundColor: '#ffffff',
    color: '#030303',
  },
  PopupMenu: {
    position: 'absolute',
    top: '64px',
    right: '0px', // Stick to the right edge of the screen
    backgroundColor: '#030303',
    borderRadius: '0 0 11px 11px', // Rounded corners on the bottom only
    boxShadow: '0px 1px 12px rgba(193,193,193,0.25)',
    zIndex: 1000,
    overflow: 'hidden',
    maxHeight: '0',
    width: '161px',
    transition: 'max-height 0.5s ease-out, padding 0.5s ease-out', // Sliding animation
    padding: '0 0', // Start with no padding to prevent content visibility during the animation
  },
  PopupMenuVisible: {
    maxHeight: '500px', // Adjust this to be higher than the total height of the menu items
    padding: '8px 0', // Add padding when menu is visible
  },
  MenuItem: {
    padding: '12px 16px',
    color: '#ffffff',
    textDecoration: 'none',
    display: 'block',
    cursor: 'pointer',
    fontWeight: 'bold',
    position: 'relative', // For positioning the underline correctly under the text
    transition: 'color 0.3s ease',
  },
  Underline: {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '2px',
    bottom: '-2px', // Position just below the text
    left: '0',
    backgroundColor: '#ffffff',
    transform: 'scaleX(0)', // Initially hidden
    transition: 'transform 0.3s ease', // Smooth underline transition
  },
  ImageContainer: {
    top: '11px',
    left: '16px',
    width: '82px',
    height: '43px',
    borderRadius: '8px',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginRight: '16px', // Add space between the logo and the text
  },
};

const defaultProps = {
  image: 'https://assets.api.uizard.io/api/cdn/stream/568b6591-f5be-4e42-8b0d-f3e3edbaa7fe.jpg',
}

const Header = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const handleMenuItemClick = (e, sectionId) => {
    e.preventDefault();
    const headerHeight = 64; // Adjust this to match your header's height
    const section = document.getElementById(sectionId);
  
    // Calculate the scroll position minus the header height
    const sectionPosition = section.getBoundingClientRect().top + window.scrollY - headerHeight;
  
    // Scroll to the calculated position
    window.scrollTo({
      top: sectionPosition,
      behavior: 'smooth',
    });
  
    closeMenu();
  };

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <div style={styles.Header}>
      <div style={styles.LogoContainer}>
        <div
          style={{
            ...styles.ImageContainer,
            backgroundImage: `url(${defaultProps.image})`,
          }}
        />
      <div style={styles.Text}>
        Krinke Outfitters
      </div>
      </div>
      <svg style={styles.Icon} viewBox="0 0 448 512" onClick={toggleMenu}>
        <path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z">
        </path>
      </svg>
      <div
        style={{
          ...styles.PopupMenu,
          ...(menuVisible ? styles.PopupMenuVisible : {}),
        }}
      >
        {['Home', 'Pricing', 'Available-Dates', 'Accommodations', 'Gallery', 'FAQ', 'BookYourHunt'].map((item) => (
          <a
            href={`#${item}`}
            style={{
              ...styles.MenuItem,
              ...(hoveredItem === item ? styles.MenuItemHover : { }),
            }}
            onClick={(e) => handleMenuItemClick(e, item)}
            onMouseEnter={() => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
            key={item}
          >
            <span style={{ position: 'relative' }}>
              {item.replace('-', ' ').replace(/\b\w/g, char => char.toUpperCase())}
              <span
                style={{
                  ...styles.Underline,
                  transform: hoveredItem === item ? 'scaleX(1)' : 'scaleX(0)',
                }}
              ></span>
            </span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Header;
