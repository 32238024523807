import React from 'react';

const styles = {
  PricingContainer: {
    padding: '16px',
    textAlign: 'center',
    position: 'relative',
    maxWidth: '800px',
    margin: '0 auto',
  },
  Title: {
    color: '#333333',
    fontSize: '20px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    lineHeight: '24px',
    marginBottom: '16px',
  },
  CardContainer: {
    backgroundColor: '#fdead7',
    borderRadius: '11px',
    boxShadow: '2px 0px 10px rgba(3,3,3,0.1)',
    padding: '16px',
    marginBottom: '16px',
  },
  Text: {
    color: '#030303',
    fontSize: '14px',
    fontFamily: 'Roboto',
    lineHeight: '16px',
  },
};

const Pricing = (props) => {
  return (
    
    <div style={styles.PricingContainer}>
      
      <div style={styles.Title}>
        {props.title ?? 'Pricing'}
      </div>
      <div style={styles.CardContainer}>
        <div style={styles.Text}>
          {props.text ?? 'Full Day Hunt: $150 per person'}
        </div>
        <div style={styles.Text}>
          Half Day Hunt: $100 per person
        </div>
        <div style={styles.Text}>
          Half Day Youth Hunt (under 16): $50 per person
        </div>
        <div style={styles.Text}>
          Full Day Youth Hunt (under 16): $100 per person
        </div>
        <div style={styles.Text}>
          <b>We require half down at time of booking.</b>
        </div>
      </div>
    </div>
    
  );
};

export default Pricing;
