import React, { useState } from 'react';

const styles = {
  FAQContainer: {
    padding: '16px',
    textAlign: 'center',
    position: 'relative',
    maxWidth: '800px',
    margin: '0 auto',
  },
  Title: {
    color: '#333333',
    fontSize: '20px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    lineHeight: '24px',
    marginBottom: '16px',
  },
  CardContainer: {
    backgroundColor: '#fdead7',
    borderRadius: '11px',
    boxShadow: '2px 0px 10px rgba(3,3,3,0.1)',
    padding: '16px',
    marginBottom: '16px',
    textAlign: 'left',
  },
  AccordionItem: {
    marginBottom: '8px',
  },
  Question: {
    cursor: 'pointer',
    padding: '10px',
    backgroundColor: '#fdb060',
    borderRadius: '8px',
    fontWeight: 'bold',
  },
  Answer: {
    padding: '10px',
    display: 'none',
  },
  AnswerVisible: {
    display: 'block',
  },
};

const FAQ = (props) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: 'What does a hunting day look like?',
      answer: 'We will meet at the field one hour before sunrise. Transportation will be provided from the hunting area to the decoy spread. After a safety briefing, we will hunt until 11:30 AM, followed by a break until 3:00 PM. You are welcome to stay in the field during the break if you wish. We will resume hunting from 3:00 PM until half an hour after sunset. Afterward, we can take pictures and transport you back to your vehicles.',
    },
    {
      question: 'What gear do I need to bring?',
      answer: 'We recommend bringing clothing appropriate for the weather (Sometimes its warm sometimes its cold), Gun, Ammo 3in or 3.5 inch steel shot, case of shells for 3 day trip, huntings bag with snacks/refreshments, and a camera to capture the memories.',
    },
    {
      question: 'What gear is provided?',
      answer: 'You will be in the heart of the action, hunting from comfortable layout blinds surrounded by over 2,500 SkyFly decoys. With multiple rotary machines, flyers, and e-callers strategically placed, you’ll feel the adrenaline as the birds are drawn in close. This setup is designed to give you the ultimate edge and make every moment in the field thrilling and successful.',
    },
    {
      question: 'Can I bring a dog?',
      answer: 'Dogs are welcome if you are hunting with a party of 4 or more. If hunting with less than 4 people please reach out for more information.',
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div style={styles.FAQContainer}>
      <div style={styles.Title}>
        {props.title ?? 'FAQ'}
      </div>
      <div style={styles.CardContainer}>
        {faqs.map((faq, index) => (
          <div key={index} style={styles.AccordionItem}>
            <div
              style={styles.Question}
              onClick={() => toggleAccordion(index)}
            >
              Q: {faq.question}
            </div>
            <div
              style={{
                ...styles.Answer,
                ...(activeIndex === index ? styles.AnswerVisible : {}),
              }}
            >
              A: {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
