import React from 'react';

const styles = {
  AccommodationsContainer: {
    padding: '16px',
    textAlign: 'center',
    position: 'relative',
    maxWidth: '800px',
    margin: '0 auto',
  },
  Title: {
    color: '#333333',
    fontSize: '20px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    lineHeight: '24px',
    marginBottom: '16px',
  },
  CardContainer: {
    backgroundColor: '#fdead7',
    borderRadius: '11px',
    boxShadow: '2px 0px 10px rgba(3,3,3,0.1)',
    padding: '16px',
    marginBottom: '16px',
    textAlign: 'left', // Align text to the left inside the card
  },
  ContentText: {
    color: '#030303',
    fontSize: '14px',
    fontFamily: 'Roboto',
    lineHeight: '16px',
    marginBottom: '8px', // Add space between paragraphs
  },
};

const Accommodations = (props) => {
  return (
    <div style={styles.AccommodationsContainer}>
      <div style={styles.Title}>
        {props.title ?? 'Accommodations'}
      </div>
      <div style={styles.CardContainer}>
        <div style={styles.ContentText}>
          Lodging is located nearby in Marysville, MO. <b>(Lodging not included in hunting package)</b> We recommend staying at the Bearcat Inn & Suites.
        </div>
      </div>
    </div>
  );
};

export default Accommodations;
