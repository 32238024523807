import React from 'react';

const styles = {
  AvailableDatesContainer: {
    padding: '16px',
    textAlign: 'center',
    position: 'relative',
    maxWidth: '800px',
    margin: '0 auto',
  },
  Title: {
    color: '#333333',
    fontSize: '20px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    lineHeight: '24px',
    marginBottom: '16px',
  },
  CardContainer: {
    backgroundColor: '#fdead7',
    borderRadius: '11px',
    boxShadow: '2px 0px 10px rgba(3,3,3,0.1)',
    padding: '16px',
    marginBottom: '16px',
  },
  DateText: {
    color: '#030303',
    fontSize: '14px',
    fontFamily: 'Roboto',
    lineHeight: '16px',
    marginBottom: '12px', // Space between the date lines
  },
};

const AvailableDates = (props) => {
  return (
    <div style={styles.AvailableDatesContainer}>
      <div style={styles.Title}>
        {props.title ?? 'Available Dates'}
      </div>
      <div style={styles.CardContainer}>
        <div style={styles.DateText}>
          Feburary 7 - March 15
        </div>
      </div>
    </div>
  );
};

export default AvailableDates;
