import React, { useState, useEffect } from 'react';
import axios from 'axios';

const styles = {
  FormContainer: {
    padding: '16px',
    textAlign: 'center',
    position: 'relative',
    maxWidth: '800px',
    margin: '0 auto',
  },
  Title: {
    color: '#333333',
    fontSize: '20px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    lineHeight: '24px',
    marginBottom: '16px',
  },
  CardContainer: {
    backgroundColor: '#fdead7',
    borderRadius: '11px',
    boxShadow: '2px 0px 10px rgba(3,3,3,0.1)',
    padding: '16px',
    marginBottom: '16px',
  },
  Input: {
    width: '100%',
    height: '50px',
    padding: '0px 8px',
    marginBottom: '12px',
    border: '1px solid #c09050',
    boxSizing: 'border-box',
    borderRadius: '11px',
    boxShadow: '0px 1px 0px rgba(3,3,3,0.1)',
    backgroundColor: '#fdb060',
    color: '#333333',
    fontSize: '13px',
    fontFamily: 'Roboto',
    lineHeight: '50px',
    outline: 'none',
  },
  TextArea: {
    width: '100%',
    padding: '8px',
    marginBottom: '12px',
    border: '1px solid #c09050',
    boxSizing: 'border-box',
    borderRadius: '11px',
    boxShadow: '0px 1px 0px rgba(3,3,3,0.1)',
    backgroundColor: '#fdb060',
    color: '#333333',
    fontSize: '13px',
    fontFamily: 'Roboto',
    lineHeight: '24px',
    outline: 'none',
    resize: 'vertical',
  },
  Button: {
    cursor: 'pointer',
    width: '100%',
    height: '48px',
    padding: '0px 8px',
    border: '0',
    boxSizing: 'border-box',
    borderRadius: '11px',
    backgroundColor: '#726b3c',
    color: '#ffffff',
    fontSize: '18px',
    fontFamily: 'Roboto',
    lineHeight: '21px',
    outline: 'none',
  },
};

const BookYourHunt = (props) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    question: '',
  });
  const [recaptchaToken, setRecaptchaToken] = useState('');

  useEffect(() => {
    // Load the reCAPTCHA script dynamically
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=6LeEVDIqAAAAALTDOX8UlPX9rp1R3xiOitxVKakE`;
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleRecaptcha = async (action) => {
    return new Promise((resolve) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6LeEVDIqAAAAALTDOX8UlPX9rp1R3xiOitxVKakE', { action }).then((token) => {
          resolve(token);
        });
      });
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const recaptchaToken = await handleRecaptcha('submit');

    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    const data = {
      name: formData.fullName,
      email: formData.email,
      phone: formData.phone,
      question: formData.question,
      recaptchaToken,
    };

    try {
      const response = await axios.post('https://hlmj9m69ab.execute-api.us-east-1.amazonaws.com/prod/submit', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      alert(response.data.message);
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send the message');
    }
  };

  return (
    <div style={styles.FormContainer}>
      <div style={styles.Title}>
        {props.title ?? 'Book Your Hunt or Ask a Question Here'}
      </div>
      <div style={styles.CardContainer}>
        <form onSubmit={handleSubmit}>
          <input
            style={styles.Input}
            type="text"
            name="fullName"
            placeholder="Full Name"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
          <input
            style={styles.Input}
            type="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            style={styles.Input}
            type="tel"
            name="phone"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <textarea
            style={styles.TextArea}
            name="question"
            placeholder="Your Question"
            value={formData.question}
            onChange={handleChange}
            rows="4"
          />
          <button type="submit" style={styles.Button}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default BookYourHunt;
